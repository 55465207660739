header {
    background: transparent;
    position: fixed;
    bottom: 0rem;
    width: 100%;
    color: $color-primary;
    z-index: 3;


    padding: 1rem 0;
    transition: background 0.25s linear,
                padding 0.25s ease-out;

    // @include respond-to('small-and-medium') {
    //     color: $light-grey;
    // }

    @include respond-to('large') {
        bottom: auto;
    }

    &.scrolled {
        background: $color-primary;

        @include respond-to('small-to-large') {
            color: $light-grey;
        }

        @include respond-to('large') {
            padding:0.2rem 0;
            color: $light-grey;
            background: $color-primary;
            nav .logo {
            transform: scale(0.7);
            }
        }
        
        
    }
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;

    @include respond-to('medium') {
        padding: 0 4rem;
    }

    .logo {
        transition: transform 0.25s ease-in-out;
        width: 200px;

        @include respond-to('large') {
            width: 320px;
        }
    }
    .nav-links {
        display: flex;
        align-items:center;
    }
    a.nav-link {
        display: inline-block;
        position: relative;
        margin-left: 1.75rem;
        font-size: 1.2rem;
        font-weight: 500;
        transition: color 0.25s linear;
        animation-delay:  0.5s;

        @include respond-to('large') {
            margin-left: 4rem;
            // font-size: 2rem;
            // color: $white;

            &:last-of-type {
                margin-right: 4rem;
            }
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 100%;
            height: 2px;
            background: $color-secondary;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform .35s ease-out;
        }

        &:hover:after {
            transform: scale(1);
        }
    }
}

@for $i from 1 through 10 {
    nav a.fade-in:nth-child(#{$i}n) {
        animation-duration: #{$i * .5}s;
    }
}