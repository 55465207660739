section {
    position: relative;
    padding: 2rem 2rem calc(75px + 2rem);

    .flex-mobile-reverse {
            display:flex;
            flex-direction: column-reverse;
        }

    @include respond-to('large') {
        min-height: 100vh;
    }
    .section-col {
        z-index: 1;
        margin-bottom: 6rem;
        display: flex;
        flex-direction: column;

        &.padding-hero {
            padding-bottom: 30%;
        }

        @include respond-to('large') {
            &.padding-hero {
                padding-bottom: 0;
            }   
            width: 50%;
            margin-bottom: 0;

            &.col-full-width {
                width: 100%;
            }

            &.col-left {
                padding-right: 4rem;
            }

            &.col-right {
                padding-left: 4rem;
            }

            &.col-align-end {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;

                // IE Bug fix
                > h3 {
                    width: 100%
                }
            }
        }
    }
    &.section--services {
        @include respond-to('large') {
            // set height to fix IE min-height bug
            height: 100px;
            &:after {
                content:'';
                position:absolute;
                width:60%;
                opacity:0.35;
                height:60%;
                background-image: url('/assets/images/wireframe_devices_bg.svg');
                background-repeat: no-repeat;
                background-position: left;
            }
        }
    }
    &.section--team {
        display: block;
    }

    @include respond-to('medium') {
        padding: calc(75px + 4rem) 4rem;
    }

    @include respond-to('large') {
        display: flex;

        &.section--align-center {
            align-items: center;
        }
    }
}