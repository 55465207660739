.btn {
    display: inline-block;
    outline: none;
    border: none;
    // font-size: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 4px;
    font-weight: 600;
    background: transparent;

    @include respond-to('large') {
        // font-size: 2rem;
        padding: 1rem 2rem;
    }
}

.btn-default {
    background: $color-secondary;
    border: 2px solid transparent;
    color: $color-primary;
    box-shadow: $box-shadow-primary;
    max-width: 250px;
    transition: all 0.35s linear;

    &:hover {
        background: darken($color-secondary, 5%);
        transform: translateY(-2px);
    }
}

.cookie-btn {
    color: $color-primary;
    border: none;
    background-color: $color-secondary;
    border-radius: 4px;
    padding: 0.3rem 1rem;
    margin-left: 1rem;
    transition: all 0.35s linear;
    font-weight: normal;

    &:hover {
        background: darken($color-secondary, 5%);
        transform: translateY(-2px)
    }
}
