//? set your brand colors

$white: #fff;

$light-grey: #eee;
$grey: #737687;
$dark-grey: #434656;

$black-ish: #444;
$black: #09090e;

$blue: #2c638f;
$pink: #fff0f5;
$yellow: #fffacd;

$color-primary: #272727;
$color-secondary: #ffbf00;
$color-typography: #424656;

$box-shadow-primary: 0 1px 3px rgba(36,180,126,.4);