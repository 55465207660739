img.hero-img {
    position: absolute;
    width: 80%;
    bottom:10%;
    left: 1rem;
    right: 1rem;
    z-index: -1;
    margin:0 auto;

    @include respond-to('medium') {
        max-width: 75%;
        left: auto;
        right: 1rem;
    }

    @include respond-to('large') {
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 45%;
        z-index: 1;

        &.right {
            left: auto;
            right: 4rem;
        }
        &.left {
            left: 4rem;
            right: auto;
        }
    }
}