*::selection {
  background: $color-secondary;
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  background: #eee;
  // background-image: linear-gradient(to left bottom, #00257e, #123693, #1f47a9, #2a59bf, #356cd6, #3e77e0, #4883eb, #518ef5, #5c95f6, #669cf6, #71a3f7, #7baaf7);
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 2rem 0;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  display: block;
}

ul {
  padding: 0;
  list-style: none;
  margin:0;

  li {
    display: flex !important;
    align-items: center;
    padding: 1rem 0;
    font-weight: 600;

    &.with-icon:before { 
      content: ''; 
      display: inline-block; 
      height: 2rem; 
      background-image: url('/assets/images/icons/icon_checked.svg'); 
      background-size: contain; 
      background-repeat: no-repeat; 
      padding-left: 4rem;
      vertical-align: middle;
    }
    &.with-icon-yellow:before { 
      content: ''; 
      display: inline-block; 
      height: 2rem; 
      background-image: url('/assets/images/icons/icon_checked_yellow.svg'); 
      background-size: contain; 
      background-repeat: no-repeat; 
      padding-left: 4rem;
      vertical-align: middle;
    }
  }
}

label {
  display: block;
}

input, textarea {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin: 1rem 0;
  background: rgba(255,255,255,0.1);
  border: 2px solid #272727;
  border-radius: 8px;
  transition: all 0.25s linear;

  &:focus, &:active, &:hover {
    outline: none;
    border: 2px solid lighten($color-primary, 50%);
  }
}

textarea {
  min-height: 200px;
  resize: vertical;
}

.social-wrapper {
  position: absolute;
  bottom: 175px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
  padding-left: 1rem;

  svg {
    fill: $color-primary;
    color: $color-primary;
  }

  @include respond-to('large') {
    position: relative;
    bottom: auto;
  }
  

  &:after {
    content: '';
    position: absolute;
    bottom: -8rem;
    left: calc(2rem - 1px);
    height: 7rem;
    width: 2px;
    background: $color-primary;
  }
}

.contact-link {
  display: inline-block;
  position: relative;
  color: $color-secondary;
  font-size: 1.2rem;

  a {
    color: $color-secondary;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    // right: -7rem;
    height: 2px;
    width: 6rem;
    background: $color-secondary;

    @include respond-to('large') {
      left: -7rem;
      right: auto;
    }
  }
}

.cookies-message {
  z-index:1000;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 4.5rem;
  width: 100%;
  max-height: 50px;
  overflow: hidden;

  @include respond-to('large') {
      bottom: 0;
  }

  p {
      background-color: rgba(0, 0, 0, 0.4);
      color: white;
      padding: 10px;
      margin-top: 0%;
      .read-more {
        color: $color-secondary;
      }
  }
}
