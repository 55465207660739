footer {
    display: block;
    padding: 4rem 2rem calc(50px + 4rem);
    background: lighten($color-primary,10%);
    color: $white;

    ul {
        li {
            padding: 0.5rem 0;
        }
    }
    @include respond-to('large') {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 4rem;
        grid-row-gap: 2rem;
        justify-items: stretch;
        padding: 2rem 4rem;
    }
}