.team-profiles-wrapper {
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: repeat(5,25%);
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
    overflow-x: auto;
}

.team-profile {
    position: relative;
    height: 100%;
    width: 100%;
    background-size: cover;
    cursor: pointer;
    filter: grayscale(1);
    transition: filter 0.35s linear;

    .team-profile--inner {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 1rem 2rem;
        background: rgba(255,255,255,0.9);
        color: $color-primary;
        font-size: 1.2rem;
        opacity: 0;
        transition: opacity 0.35s linear;

        .team-profile--inner-content {
            opacity: 0;
            transform: translateY(4rem);
            transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.5s linear;
        }
    }

    &:hover {
        filter: grayscale(0);

        .team-profile--inner {
            transform: translateY(0);
            opacity: 1;
        }

        .team-profile--inner-content {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

html.no-webp {
    .team-profile--alex {
        background-image: url(/assets/images/team/alex.jpg);
    }
    .team-profile--chris {
        background-image: url(/assets/images/team/chris.jpg);
    }
    .team-profile--dave {
        background-image: url(/assets/images/team/dave.jpg);
    }
    .team-profile--hubert {
        background-image: url(/assets/images/team/hubert.jpg);
    }
    .team-profile--joe {
        background-image: url(/assets/images/team/joe.jpg);
    }
    .team-profile--nye {
        background-image: url(/assets/images/team/nye.jpg);
    }
    .team-profile--paulb {
        background-image: url(/assets/images/team/paulb.jpg);
    }
    .team-profile--paulk {
        background-image: url(/assets/images/team/paulk.jpg);
    }
    .team-profile--victor {
        background-image: url(/assets/images/team/victor.jpg);
    }
}
// Highly optimized format not supported by all browsers
html.webp {
    .team-profile--alex {
        background-image: url(/assets/images/team/alex.webp);
    }
    .team-profile--chris {
        background-image: url(/assets/images/team/chris.webp);
    }
    .team-profile--dave {
        background-image: url(/assets/images/team/dave.webp);
    }
    .team-profile--hubert {
        background-image: url(/assets/images/team/hubert.webp);
    }
    .team-profile--joe {
        background-image: url(/assets/images/team/joe.webp);
    }
    .team-profile--nye {
        background-image: url(/assets/images/team/nye.webp);
    }
    .team-profile--paulb {
        background-image: url(/assets/images/team/paulb.webp);
    }
    .team-profile--paulk {
        background-image: url(/assets/images/team/paulk.webp);
    }
    .team-profile--victor {
        background-image: url(/assets/images/team/victor.webp);
    }
}