.flex-column {
    display: flex;
    flex-direction: column;
}
.flex-row {
    display: flex;
    align-items: center;

    @include respond-to('large') {
        &.flex-end {
            justify-content: flex-end;

            p {
                text-align: right;
            }
        }
    }
}
.m-right-1x {
    margin-right: 1rem;
}
.m-top-1x {
    margin-top: 1rem;
}
.m-top-2x {
    margin-top: 2rem;
}
.m-top-4x {
    margin-top: 4rem;
}
.max-w-75 {
    max-width: none;

    @include respond-to('large') {
        max-width: 75%;
    }
}
.rotate-180 {
    transform: rotate(180deg);
}
.show-on-lg {
    display: none;

    @include respond-to('large') {
        display: block;
    }
}
.svg-icon {
    display: inline;
    vertical-align: middle;

    &.svg-icon--sm {
        width: 2rem;
        height: 2rem;
    }
    &.svg-icon--md {
        width: 3rem;
        height: 3rem;
    }
}
.txt-a-right {
    text-align: right;
}
.txt-a-right--lg {
    @include respond-to('large') {
        text-align: right;
    }
}
.txt-c-trans-6 {

}
.txt-c-trans-8 {
 
}
.txt-sm {
    font-size: 0.8rem;
}
.txt-lg {
    font-size: 1.3rem;
}
.txt-w-600 {
    font-weight: 600;
}
.w-75 {
    width: 75%;
}
.w-100 {
    width: 100%;
}
